@import url("styles.css");
@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
    .drive_side_bar {
        @apply flex gap-3 items-center justify-center w-full flex-[250px] flex-col;
    }
    .drive_drop_zone {
        @apply flex-[100]  h-[calc(100vh-88px)];
    }
    .upload_link {
        @apply inline-block text-base px-3;
    }
    .drive_btn {
        @apply w-full inline-flex justify-between;
    }
    .drive_icon {
        @apply w-[24px] h-[24px];
    }
    .primary_btn_class {
        @apply text-white items-center px-4 py-3 border border-transparent rounded-md font-semibold text-xs uppercase tracking-widest bg-slate-600 hover:bg-slate-400 focus:ring-2 focus:outline-none focus:ring-offset-2 transition ease-in-out duration-150;
    }
    .secondary_btn_class {
        @apply inline-flex items-center px-4 py-2 bg-white border border-gray-300 rounded-md font-semibold text-xs text-gray-700 uppercase tracking-widest shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-25 transition ease-in-out duration-150;
    }
    .progress_bar {
        @apply flex;
    }
    .radio_btn{
        @apply inline-flex gap-2 items-center;
    }
    .navigation{
        @apply bg-slate-400 flex gap-4 justify-between;
    }
}
