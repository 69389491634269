:root {
  --primary-bg-color: #00a3ed;
  --primary-button-color: #4cafd3;
  --primary-button-hover-color: #4cbef2;
  --primary-button-text-color: #e6f4f1;
  --primary-user-menu-text-color: #007a9e;
  --burger-menu-color: #525252;
  --Primary-menu-text-color: #00a3ed;
  --Primary-menu-bg-color: #e6fdff;
  --primary-link-color: #007a9e;
  --primary-shadow-color: #525252;
  --primary-gradent: linear-gradient(90deg, #4cbef2 0%, #4ca4c1 100%);
  --primary-lg-border-radius: 10px;
}
@keyframes contentDot {
  0% {
    content: ".";
  }
  66% {
    content: "..";
  }
  100% {
    content: "...";
  }
}
@font-face {
  font-family: Montserrat;
  src: url("/assets/fonts/montserrat/Montserrat-Regular.ttf") format("truetype");
}
@font-face {
  font-family: JosefinSans;
  src: url("/assets/fonts/josefin/JosefinSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: Quicksand;
  src: url("/assets/fonts/quicksand/Quicksand-Regular.ttf") format("truetype");
}
.josefin {
  font-family: JosefinSans, "Calibri", "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
.quicksand {
  font-family: Quicksand, "Calibri", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.montserrat {
  font-family: Montserrat, "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.PrimarybackgroundColor {
  background: -webkit-linear-gradient(90deg, #4cbef2 0%, #4ca4c1 100%) !important;
  background: -moz-linear-gradient(90deg, #4cbef2 0%, #4ca4c1 100%) !important;
  background: -ms-linear-gradient(90deg, #4cbef2 0%, #4ca4c1 100%) !important;
  background: -o-linear-gradient(90deg, #4cbef2 0%, #4ca4c1 100%) !important;
  background: linear-gradient(90deg, #4cbef2 0%, #4ca4c1 100%) !important;
  background-color: var(--primary-bg-color, #00a3ed);
}
html,
body,
body div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
figure,
footer,
header,
menu,
nav,
section,
time,
mark,
audio,
video,
details,
summary {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent;
}
main,
article,
aside,
figure,
footer,
header,
nav,
section,
details,
summary {
  display: block;
}
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
img,
object,
embed {
  max-width: 100%;
}
html {
  overflow-y: scroll;
}
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  color: inherit;
}
del {
  text-decoration: line-through;
}
abbr[title],
dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help;
}
table {
  border-collapse: separate;
  border-spacing: 0;
}
th {
  font-weight: bold;
  vertical-align: bottom;
}
td {
  font-weight: normal;
  vertical-align: top;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}
input,
select {
  vertical-align: middle;
}
pre {
  white-space: pre;
  /* CSS2 */
  white-space: pre-wrap;
  /* CSS 2.1 */
  white-space: pre-line;
  /* CSS 3 (and 2.1 as well, actually) */
  word-wrap: break-word;
  /* IE */
}
input[type="radio"] {
  vertical-align: text-bottom;
}
input[type="checkbox"] {
  vertical-align: bottom;
}
.ie7 input[type="checkbox"] {
  vertical-align: baseline;
}
.ie6 input {
  vertical-align: text-bottom;
}
select,
input,
textarea {
  font: 99% sans-serif;
}
table {
  font-size: inherit;
  font: 100%;
}
small {
  font-size: 85%;
}
strong {
  font-weight: bold;
}
td,
td img {
  vertical-align: top;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
pre,
code,
kbd,
samp {
  font-family: monospace, sans-serif;
}
.clickable,
label,
input[type=button],
input[type=submit],
input[type=file],
button {
  cursor: pointer;
  border: none;
}
button,
input,
select,
textarea {
  margin: 0;
  padding: 0;
}
button,
input[type=button] {
  width: auto;
  overflow: visible;
}
.ie7 img {
  -ms-interpolation-mode: bicubic;
}
.clearfix:after {
  content: " ";
  display: block;
  clear: both;
}
h1,
h2,
h3,
h4,
h5,
h6,
.title,
.description {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 100%;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2.7rem;
}
h3,
.title {
  font-size: 2.5rem;
}
h4 {
  font-size: 2rem;
}
h5,
.description {
  font-size: 1.2rem;
}
h6 {
  font-size: 1rem;
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.image-icon,
.logo-icon {
  max-height: 80px;
  width: auto;
}
.inner-wrapper {
  padding: 40px 20px;
  position: relative;
  z-index: 1;
}
.body-wrapper {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
